module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"映画「いのちめぐる島 イリオモテ」公式サイト","short_name":"いのちめぐる島 イリオモテ","start_url":"/","background_color":"#ffffff","theme_color":"#333333","display":"minimal-ui","icon":"src/static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a26c220c95f54403072f108af442e88f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
